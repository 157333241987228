// ** MUI Imports
import {
  StormRounded,
  SwitchAccessShortcutAddRounded,
} from "@mui/icons-material";
import { Box, Tooltip, Typography } from "@mui/material";

// ** Types
type ProductivityForMachineProps = {
  availibilityValue?: number;
  performanceValue?: number;
};

const ProductivityForMachine = (props: ProductivityForMachineProps) => {
  const { availibilityValue = 0, performanceValue = 0 } = props;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
      }}
    >
      <Tooltip title="Kullanılabilirlik" placement="bottom">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            px: 1,
            gap: 1,
          }}
        >
          <SwitchAccessShortcutAddRounded
            sx={{
              fontSize: 24,
              color: "rgb(255, 139, 0)",
              filter: "drop-shadow(1px 3px 5px rgba(255, 139, 0, 0.81))",
            }}
          />
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "0.75rem",
              color: "rgb(255, 139, 0)",
            }}
          >
            {availibilityValue.toFixed(1)} %
          </Typography>
        </Box>
      </Tooltip>
      <Tooltip title="Performans" placement="bottom">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            px: 1,
            gap: 1,
          }}
        >
          <StormRounded
            sx={{
              fontSize: 24,
              color: "rgb(253, 202, 64)",
              filter: "drop-shadow(1px 3px 5px rgba(253, 202, 64, 0.81))",
            }}
          />
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "0.75rem",
              color: "rgb(253, 202, 64)",
            }}
          >
            {performanceValue.toFixed(1)} %
          </Typography>
        </Box>
      </Tooltip>
    </Box>
  );
};

export default ProductivityForMachine;
