// ** Icons Imports
import {
  InfoTwoTone,
  MoodRounded,
  SentimentDissatisfiedRounded,
  SentimentNeutralRounded,
  SentimentSatisfiedAltRounded,
} from "@mui/icons-material";

// ** MUI Imports
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import DebugModeInfo from "./DebugModeInfo";

// ** Types
type ProductivityProps = {
  productivityValue?: number;
  onDialogOpen: () => void;
};

const Productivity = (props: ProductivityProps) => {
  const { productivityValue = 39, onDialogOpen } = props;

  const [productivityColor, setProductivityValue] =
    useState<string>("2, 224, 164");
  const [productivityEmoji, setProductivityEmoji] = useState<any>();

  const returnColor = () => {
    if (productivityValue >= 85) {
      return "2, 224, 164";
    }

    if (productivityValue >= 60) {
      return "131, 217, 20";
    }

    if (productivityValue >= 40) {
      return "243, 156, 18";
    }

    return "255, 76, 81";
  };

  useEffect(() => {
    setProductivityValue(returnColor());
    setProductivityEmoji(returnEmoji());
  }, [productivityValue]);

  const returnEmoji = () => {
    if (productivityValue >= 85) {
      return (
        <MoodRounded
          sx={{
            fontSize: 48,
          }}
        />
      );
    }

    if (productivityValue >= 60) {
      return <SentimentSatisfiedAltRounded sx={{ fontSize: 48 }} />;
    }

    if (productivityValue >= 40) {
      return (
        <SentimentNeutralRounded
          sx={{
            fontSize: 48,
          }}
        />
      );
    }

    return (
      <SentimentDissatisfiedRounded
        sx={{
          fontSize: 48,
        }}
      />
    );
  };

  return (
    <div
      className="card"
      style={{
        width: 185,
        paddingTop: 8,
        paddingRight: 12,
        paddingBottom: 8,
        paddingLeft: 12,
        margin: 5,
        borderBottom: `1px solid rgb(${productivityColor})`,
        cursor: "pointer",
      }}
      onClick={onDialogOpen}
    >
      <DebugModeInfo label="LineOEE" top={-10} />
      <InfoTwoTone
        sx={{
          position: "absolute",
          top: 5,
          right: 5,
          fontSize: "1rem",
          color: "#d0d3d4",
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          minHeight: 72,
        }}
      >
        <Box
          sx={{
            width: 42,
            p: 0,
            height: 42,
            mr: 1.5,
            display: "flex",
            borderRadius: "50%",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: `rgba(${productivityColor}, 0.21)`,
            color: `rgb(${productivityColor})`,
          }}
        >
          {productivityEmoji}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "1.1rem",
              lineHeight: "1.1rem",
              color: "#CACFD2",
            }}
          >
            Verim (OEE)
          </Typography>
          <Typography
            sx={{
              mt: 1,
              fontWeight: 700,
              fontSize: "1.2rem",
              lineHeight: "1.2rem",
              color: "#FBFCFC",
            }}
          >
            {productivityValue && productivityValue.toFixed(1)} %
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default Productivity;
