import React, { useCallback, useEffect, useState } from "react";
import MachineLight from "../components/MachineLight";
import type {} from "@mui/x-data-grid/themeAugmentation";
import { useKeyPress } from "@react-typed-hooks/use-key-press";

import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  LinearProgress,
  Toolbar,
  Tooltip,
  Typography,
  linearProgressClasses,
} from "@mui/material";
import {
  AccessTimeRounded,
  AutoModeRounded,
  BarChartRounded,
  CallSplitRounded,
  CloseRounded,
  DoneOutlineRounded,
  InfoTwoTone,
  Inventory2Rounded,
  PersonRounded,
  QrCodeRounded,
  StormRounded,
  SwitchAccessShortcutAddRounded,
  ThreeSixtyRounded,
  TodayRounded,
} from "@mui/icons-material";
import Productivity from "../components/Productivity";
import SelectedMachineInfoDialog from "../components/SelectedMachineInfoDialog";
import ProductivityInfoDialog from "../components/ProductivityInfoDialog";
import axios from "axios";
import { useParams } from "react-router-dom";
import DebugModeInfo from "../components/DebugModeInfo";
import dateFormat from "dateformat";
import { i18n } from "dateformat";
import { disableDebugMode, enableDebugMode } from "../global-states/debug-mode";
import { Line, Machines, Message } from "../message";
import LineDownInfoDialog from "../components/LineDownInfoDialog";
import LineBreakInfoDialog from "../components/LineBreakInfoDialog";
import LineRunInfoDialog from "../components/LineRunInfoDialog";
import Footer from "../components/Footer";
import Logo from "../components/Logo";

function padTo2Digits(num: number) {
  return num.toString().padStart(2, "0");
}

export const convertMsToTime = (seconds: number) => {
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  minutes = minutes % 60;
  hours = hours % 24;

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
    seconds
  )}`;
};

export const convertStartedOrFinished = (timestamp: number) => {
  const time = new Date(timestamp * 1000);

  const minutes = time.getMinutes();
  const hours = time.getHours();

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`;
};

const API_URL = process.env.REACT_APP_API_HOST || "";

const PageShiftDetailsPage = () => {
  // ** States
  const [lineStateRatio, setLineStateRatio] = useState<number>(0);

  // ** Seçili menü fonksiyonu tetiklendiğinde
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    window.open(`/reports/${shiftId}`, "_blank");
  };

  const [lineDownInfoDialogState, setLineDownInfoDialogState] =
    useState<boolean>(false);

  const [lineBreakInfoDialogState, setLineBreakInfoDialogState] =
    useState<boolean>(false);

  const [lineRunInfoDialogState, setLineRunInfoDialogState] =
    useState<boolean>(false);

  const [productivityInfoDialogState, setProductivityInfoDialogState] =
    useState<boolean>(false);

  const [selectedMachine, setSelectedMachine] = useState<Machines[0] | null>(
    null
  );

  const caps = useKeyPress({ targetKey: "CapsLock" });

  useEffect(() => {
    if (caps) {
      enableDebugMode();
    } else {
      disableDebugMode();
    }
  }, [caps]);

  const [message, setMessage] = useState<Message>({
    Machines: [],
    Line: {
      Availability: 0,
      Performance: 0,
      Quality: 0,
      OEE: 0,
      Breaks: 0,
      Breaks1: 0,
      Breaks2: 0,
      DolumBottleOutCounter: 0, // ** Dolan Şişe
      DolumTurnTableFull: false,
      DownTime: 0,
      GoodCount: 0,
      Red: false,
      Yellow: false,
      Green: false,
      IdealRunRatePPM: 0,
      InBottlePieces: 0, // ** Giren Şişe
      KutulamaBoxRejectCounter: 0, // ** Kutulama Fire
      PackageOutPieces: 0, // ** Çıkan Koli
      PlannedProductionTime: 0,
      PlannedStops: 0,
      PlannedStops1: 0,
      PlannedStops2: 0,
      PresentTime: 0,
      RecycleBottles: 0,
      RejectBottleTotalPieces: 0, // ** Etiket Fire
      RejectTotalPieces: 0, // ** Toplam Fire
      VerifiedBoxTotalPieces: 0, // ** Kutulanan Ürün
      UnplannedStops: 0,
      ErrorsFromOperator: [],
      UnfillableBottlePieces: 0, // ** Dolum Fire
      VerifiedBottleTotalPieces: 0, // ** Etiketli Ürün - Çıkan Ürün
      BreaksTime: 0,
      WorkingTime: 0,
      ShiftLength: 0,
      StartProduction: false,
      StartBreaks: false,
      StartScheduledStops: false,
    },
    Time: 0,
    Connected: false,
  });

  const [productivityDatas, setProductivityDatas] = useState<Line>({
    Availability: 0,
    Performance: 0,
    Quality: 0,
    OEE: 0,
    Breaks: 0,
    Breaks1: 0,
    Breaks2: 0,
    DolumBottleOutCounter: 0, // ** Dolan Şişe
    DolumTurnTableFull: false,
    DownTime: 0,
    GoodCount: 0,
    Red: false,
    Yellow: false,
    Green: false,
    IdealRunRatePPM: 0,
    InBottlePieces: 0, // ** Giren Şişe
    KutulamaBoxRejectCounter: 0, // ** Kutulama Fire
    PackageOutPieces: 0, // ** Çıkan Koli
    PlannedProductionTime: 0,
    PlannedStops: 0,
    PlannedStops1: 0,
    PlannedStops2: 0,
    PresentTime: 0,
    RecycleBottles: 0,
    RejectBottleTotalPieces: 0, // ** Etiket Fire
    RejectTotalPieces: 0, // ** Toplam Fire
    VerifiedBoxTotalPieces: 0, // ** Kutulanan Ürün
    UnplannedStops: 0,
    ErrorsFromOperator: [],
    UnfillableBottlePieces: 0, // ** Dolum Fire
    VerifiedBottleTotalPieces: 0, // ** Etiketli Ürün - Çıkan Ürün
    BreaksTime: 0,
    WorkingTime: 0,
    ShiftLength: 0,
    StartProduction: false,
    StartBreaks: false,
    StartScheduledStops: false,
  });

  const getLineState = (line: any) => {
    if (line.Green) {
      return "2, 224, 164";
    }

    if (line.Red) {
      return "255, 76, 81";
    }

    if (line.Yellow) {
      return "243, 156, 18";
    }

    return "236, 240, 241";
  };

  const { shiftId } = useParams();

  i18n.dayNames = [
    "Paz",
    "Pzt",
    "Sal",
    "Çar",
    "Per",
    "Cum",
    "Cmt",
    "Pazar",
    "Pazartesi",
    "Salı",
    "Çarşamba",
    "Perşembe",
    "Cuma",
    "Cumartesi",
  ];

  useEffect(() => {
    setLineStateRatio(
      Number(
        (
          ((message.Line.PresentTime ? message.Line.PresentTime : 0) / 43200) *
          100
        ).toFixed(2)
      )
    );
  }, [message.Line.PresentTime]);

  const getShiftDataDetails = useCallback(async () => {
    const shiftData = await axios.get(`${API_URL}/getShiftData/${shiftId}`);
    if (shiftData.data) {
      setMessage(shiftData.data);
    }
  }, []);

  useEffect(() => {
    getShiftDataDetails();
  }, [getShiftDataDetails]);

  useEffect(() => {
    setProductivityDatas({ ...message.Line });
  }, [message]);

  return (
    <>
      <AppBar
        position="fixed"
        color="transparent"
        sx={{
          background: { xs: "transparent", md: "#1b1b1bcc" },
          backdropFilter: { xs: "blur(10px)", md: "none" },
        }}
      >
        <Toolbar sx={{ flexWrap: "wrap" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: { xs: "center", sm: "space-between" },
              flexWrap: "wrap",
              alignItems: "center",
              alignContent: "center",
              mt: 3.5,
              mb: 2,
              ml: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                flexDirection: "column",
                backdropFilter: "blur(10px)",
                borderRadius: 3,
                px: 2,
                py: 1,
              }}
            >
              <Logo />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 1,
                borderRadius: 2,
                backdropFilter: "blur(16px)",
                flexWrap: "wrap",
                gap: 0.8,
              }}
            >
              {/* 

                                  <Tooltip title="Ana Gösterim" placement="bottom">
                  <IconButton
                    sx={{
                      width: 38,
                      height: 38,
                      marginRight: 1,
                      borderRadius: "50%",
                      backgroundColor:
                        TabEnumLayout.MAIN === tabLayout
                          ? "rgba(153, 163, 164, 0.13)"
                          : "rgba(4, 158, 230, 0.13)",
                      ":hover": {
                        backgroundColor:
                          TabEnumLayout.MAIN === tabLayout
                            ? "rgba(153, 163, 164, 0.13)"
                            : "rgba(4, 158, 230, 0.21)",
                      },
                      border:
                        TabEnumLayout.MAIN === tabLayout
                          ? "1px solid #99A3A4"
                          : "1px solid #049EE6",
                      backdropFilter: "blur(10px)",
                    }}
                    onClick={() => {
                      setTabLayout(TabEnumLayout.MAIN);
                    }}
                  >
                    <ArrowBackRounded
                      fontSize="small"
                      sx={{
                        color:
                          TabEnumLayout.MAIN === tabLayout
                            ? "#99A3A4 !important"
                            : "#049EE6 !important",
                      }}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Kayıtlı Gösterim" placement="bottom">
                  <IconButton
                    sx={{
                      width: 38,
                      height: 38,
                      marginRight: 1,
                      borderRadius: "50%",
                      backgroundColor:
                        TabEnumLayout.SAVED === tabLayout
                          ? "rgba(153, 163, 164, 0.13)"
                          : "rgba(4, 158, 230, 0.13)",
                      ":hover": {
                        backgroundColor:
                          TabEnumLayout.SAVED === tabLayout
                            ? "rgba(153, 163, 164, 0.13)"
                            : "rgba(4, 158, 230, 0.21)",
                      },
                      border:
                        TabEnumLayout.SAVED === tabLayout
                          ? "1px solid #99A3A4"
                          : "1px solid #049EE6",
                      backdropFilter: "blur(10px)",
                    }}
                    onClick={() => {
                      setTabLayout(TabEnumLayout.SAVED);
                    }}
                  >
                    <ArrowForwardRounded
                      fontSize="small"
                      sx={{
                        color:
                          TabEnumLayout.SAVED === tabLayout
                            ? "#99A3A4 !important"
                            : "#049EE6 !important",
                      }}
                    />
                  </IconButton>
                </Tooltip>
                  
                  */}

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1.5,
                  pl: 0.8,
                  pr: 1.5,
                  py: 0.8,
                  borderRadius: 2,
                  maxHeight: 42,
                  background: "rgba(240, 243, 244, 0.21)",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "cneter",
                    alignItems: "center",
                    background: "rgba(240, 243, 244, 0.21)",
                    borderRadius: 1,
                    borderBottom: "2px solid rgba(240, 243, 244, 0.81)",
                  }}
                >
                  <PersonRounded
                    fontSize="small"
                    sx={{
                      m: 0.5,
                      color: "#f0f3f4",
                    }}
                  />
                </Box>

                <Typography
                  variant="subtitle2"
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  {message.operator_name ?? "Operatör Seçilmedi"}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  p: 0.8,
                  borderRadius: 2,
                  maxHeight: 42,
                }}
              >
                <Button
                  disableElevation
                  onClick={handleClick}
                  sx={{
                    pl: 1,
                    pr: 2,
                    py: 1,
                    color: "#f0f3f4",
                    textTransform: "capitalize",
                    fontSize: "1rem",
                    backgroundColor: "rgba(255, 255, 255, 0.11)",
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.21)",
                    },
                  }}
                >
                  <Box display="flex" justifyContent="flex-end">
                    <BarChartRounded
                      sx={{
                        color: "#f0f3f4 !important",
                        mr: 1,
                      }}
                    />
                    Rapor Al
                  </Box>
                </Button>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          height: "100%",
          maxHeight: "100dvh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: 3,
          }}
        >
          <Box
            sx={{
              minWidth: 128,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                gap: 1,
                flexWrap: "wrap",
              }}
            >
              <Typography
                align="center"
                sx={{
                  fontWeight: 600,
                  fontSize: "1.3rem !important",
                  color: "#FBFBFC",
                  animation: "lights 5s 750ms linear infinite",
                  "@keyframes lights": {
                    "0%": {
                      color: "rgba",
                      textShadow:
                        "0 0 1em hsla(320, 100%, 50%, 0.2), 0 0 0.13em hsla(320, 100%, 60%, 0.3), -1em -0.13em 0.5em hsla(40, 100%, 60%, 0), 1em 0.13em 0.5em hsla(200, 100%, 60%, 0)",
                    },
                    "30%": {
                      color: "hsl(230, 80%, 90%)",
                      textShadow:
                        "0 0 1em hsla(320, 100%, 50%, 0.5), 0 0 0.13em hsla(320, 100%, 60%, 0.5), -0.5em -0.13em 0.25em hsla(40, 100%, 60%, 0.2), 0.5em 0.13em 0.25em hsla(200, 100%, 60%, 0.4)",
                    },

                    "40%": {
                      color: "hsl(230, 100%, 95%)",
                      textShadow:
                        "0 0 1em hsla(320, 100%, 50%, 0.5), 0 0 0.13em hsla(320, 100%, 90%, 0.5), -0.25em -0.13em 0.13em hsla(40, 100%, 60%, 0.2), 0.25em 0.13em 0.13em hsla(200, 100%, 60%, 0.4)",
                    },

                    "70%": {
                      color: "hsl(230, 80%, 90%)",
                      textShadow:
                        "0 0 1em hsla(320, 100%, 50%, 0.5), 0 0 0.13em hsla(320, 100%, 60%, 0.5), 0.5em -0.13em 0.25em hsla(40, 100%, 60%, 0.2), -0.5em 0.13em 0.25em hsla(200, 100%, 60%, 0.4)",
                    },
                    "100%": {
                      color: "hsl(230, 40%, 80%)",
                      textShadow:
                        "0 0 1em hsla(320, 100%, 50%, 0.2), 0 0 0.13em hsla(320, 100%, 60%, 0.3), 1em -0.13em 0.5em hsla(40, 100%, 60%, 0), -1em 0.13em 0.5em hsla(200, 100%, 60%, 0)",
                    },
                  },
                }}
              >
                Kayıtlı Veri İle
              </Typography>
              <Typography
                align="center"
                sx={{
                  fontWeight: 600,
                  fontSize: "1.3rem !important",
                  color: "#C9CFF2",
                }}
              >
                ŞURUP ÜRETİM HATTI İZLEME PANELİ
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            <div
              className="card"
              style={{
                paddingBottom: 4,
                backgroundColor: `rgba(${getLineState(message.Line)}, 0.21)`,
                color: `rgb(${getLineState(message.Line)})`,
                border: `1px solid rgb(${getLineState(message.Line)})`,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  py: 1,
                  px: 1.5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    minWidth: 68,
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "0.75rem !important",
                      color: `rgb(${getLineState(message.Line)})`,
                    }}
                  >
                    Vardiya Başlangıç
                  </Typography>
                  <Typography
                    align="left"
                    sx={{
                      fontWeight: 600,
                      fontSize: "1rem !important",
                      color: `rgb(${getLineState(message.Line)})`,
                    }}
                  >
                    {convertStartedOrFinished(message.started_at || 0)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    minWidth: 68,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "1rem !important",
                      color: `rgb(${getLineState(message.Line)})`,
                    }}
                  >
                    Vardiya Süresi
                  </Typography>
                  <Typography
                    align="left"
                    sx={{
                      fontWeight: 600,
                      fontSize: "1rem !important",
                      color: `rgb(${getLineState(message.Line)})`,
                    }}
                  >
                    {convertMsToTime(message.Line.PresentTime)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    minWidth: 68,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-end",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "0.75rem !important",
                      color: `rgb(${getLineState(message.Line)})`,
                    }}
                  >
                    Vardiya Bitiş
                  </Typography>
                  <Typography
                    align="left"
                    sx={{
                      fontWeight: 600,
                      fontSize: "1rem !important",
                      color: `rgb(${getLineState(message.Line)})`,
                    }}
                  >
                    {convertStartedOrFinished(message.finished_at || 0)}
                  </Typography>
                </Box>
              </Box>
              <Box
                width="100%"
                display="flex"
                justifyContent="center"
                flexDirection="column"
                flexWrap="wrap"
                alignItems="center"
              >
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                  flexWrap="wrap"
                  alignItems="center"
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <div
                      className="card"
                      style={{
                        width: 210,
                        paddingTop: 8,
                        paddingRight: 12,
                        paddingBottom: 8,
                        paddingLeft: 12,
                        margin: 5,
                        minWidth: 180,
                        cursor: "pointer",
                        borderTop: "1px solid rgb(255, 76, 81)",
                      }}
                      onClick={() => setLineDownInfoDialogState(true)}
                    >
                      <DebugModeInfo label="LineDownTime" top={-10} />
                      <InfoTwoTone
                        sx={{
                          position: "absolute",
                          top: 5,
                          right: 5,
                          fontSize: "1rem",
                          color: "#d0d3d4",
                        }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          minHeight: 72,
                        }}
                      >
                        <Box
                          sx={{
                            width: 42,
                            p: 0,
                            height: 42,
                            display: "flex",
                            borderRadius: "50%",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "rgba(255, 76, 81, 0.21)",
                            color: "rgba(255, 76, 81)",
                          }}
                        >
                          <AccessTimeRounded
                            sx={{
                              fontSize: 48,
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            flexDirection: "column",
                            alignItems: "flex-end",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: "1.1rem",
                              lineHeight: "1.1rem",
                              color: "#CACFD2",
                            }}
                          >
                            Duruş Süresi
                          </Typography>
                          <Typography
                            sx={{
                              mt: 1,
                              fontWeight: 700,
                              fontSize: "1.2rem",
                              lineHeight: "1.2rem",
                              color: "#FBFCFC !important",
                            }}
                          >
                            {convertMsToTime(message.Line.DownTime)}
                          </Typography>
                        </Box>
                      </Box>
                    </div>
                    <div
                      className="card"
                      style={{
                        width: 210,
                        paddingTop: 8,
                        paddingRight: 12,
                        paddingBottom: 8,
                        paddingLeft: 12,
                        margin: 5,
                        minWidth: 180,
                        cursor: "pointer",
                        borderTop: "1px solid rgb(243, 156, 18)",
                      }}
                      onClick={() => setLineBreakInfoDialogState(true)}
                    >
                      <DebugModeInfo label="LineBreaksTime" top={-10} />
                      <InfoTwoTone
                        sx={{
                          position: "absolute",
                          top: 5,
                          right: 5,
                          fontSize: "1rem",
                          color: "#d0d3d4",
                        }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          minHeight: 72,
                        }}
                      >
                        <Box
                          sx={{
                            width: 42,
                            p: 0,
                            height: 42,
                            mr: 1.5,
                            display: "flex",
                            borderRadius: "50%",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "rgba(243, 156, 18, 0.21)",
                            color: "rgb(243, 156, 18)",
                          }}
                        >
                          <AccessTimeRounded
                            sx={{
                              fontSize: 48,
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            flexDirection: "column",
                            alignItems: "flex-end",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: "1.1rem",
                              lineHeight: "1.1rem",
                              color: "#CACFD2",
                            }}
                          >
                            Mola Süresi
                          </Typography>
                          <Typography
                            sx={{
                              mt: 1,
                              fontWeight: 700,
                              fontSize: "1.2rem",
                              lineHeight: "1.2rem",
                              color: "#FBFCFC",
                            }}
                          >
                            {convertMsToTime(message.Line.BreaksTime)}
                          </Typography>
                        </Box>
                      </Box>
                    </div>
                    <div
                      className="card"
                      style={{
                        width: 210,
                        paddingTop: 8,
                        paddingRight: 12,
                        paddingBottom: 8,
                        paddingLeft: 12,
                        margin: 5,
                        minWidth: 180,
                        cursor: "pointer",
                        borderTop: "1px solid rgb(2, 224, 164)",
                      }}
                      onClick={() => setLineRunInfoDialogState(true)}
                    >
                      <DebugModeInfo label="LineWorkingTime" top={-10} />
                      <InfoTwoTone
                        sx={{
                          position: "absolute",
                          top: 5,
                          right: 5,
                          fontSize: "1rem",
                          color: "#d0d3d4",
                        }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          minHeight: 72,
                        }}
                      >
                        <Box
                          sx={{
                            width: 42,
                            p: 0,
                            height: 42,
                            display: "flex",
                            borderRadius: "50%",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "rgba(2, 224, 164, 0.21)",
                            color: "rgb(2, 224, 164)",
                          }}
                        >
                          <AccessTimeRounded
                            sx={{
                              fontSize: 48,
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "flex-end",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: "1.1rem",
                              lineHeight: "1.1rem",
                              color: "#CACFD2",
                            }}
                          >
                            Çalışma Süresi
                          </Typography>
                          <Typography
                            sx={{
                              mt: 1,
                              fontWeight: 700,
                              fontSize: "1.2rem",
                              lineHeight: "1.2rem",
                              color: "#FBFCFC",
                            }}
                          >
                            {convertMsToTime(message.Line.WorkingTime)}
                          </Typography>
                        </Box>
                      </Box>
                    </div>
                    <div
                      className="card"
                      style={{
                        width: 210,
                        paddingTop: 8,
                        paddingRight: 12,
                        paddingBottom: 8,
                        paddingLeft: 12,
                        margin: 5,
                        marginRight: 9,
                        borderTop: "1px solid rgb(236, 240, 241)",
                      }}
                    >
                      <DebugModeInfo label="LineIdealRunRatePPM" top={-10} />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          minHeight: 72,
                          position: "relative",
                        }}
                      >
                        <Box
                          sx={{
                            width: 42,
                            p: 0,
                            height: 42,
                            mr: 1.5,
                            display: "flex",
                            borderRadius: 2,
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "rgba(236, 240, 241, 0.21)",
                            color: "rgb(236, 240, 241)",
                            borderBottom: "2px solid rgb(236, 240, 241)",
                          }}
                        >
                          <ThreeSixtyRounded
                            sx={{
                              fontSize: 32,
                              margin: 5,
                              filter:
                                "drop-shadow(1px 3px 5px rgba(236, 240, 241, 0.81))",
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "flex-end",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: "1.1rem",
                              lineHeight: "1.1rem",
                              color: "#CACFD2",
                            }}
                          >
                            Hat Hızı
                          </Typography>
                          <Typography
                            sx={{
                              mt: 1,
                              fontWeight: 700,
                              fontSize: "1.2rem",
                              lineHeight: "1.2rem",
                              color: "#FBFCFC",
                            }}
                          >
                            {message.Line.IdealRunRatePPM}
                          </Typography>
                          <Typography
                            sx={{
                              position: "absolute",
                              bottom: 0,
                              fontWeight: 700,
                              fontSize: "0.7rem",
                              lineHeight: "0.7rem",
                              color: "#CACFD2",
                            }}
                          >
                            adet / dk
                          </Typography>
                        </Box>
                      </Box>
                    </div>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <div
                      className="card"
                      style={{
                        width: 210,
                        paddingTop: 8,
                        paddingRight: 12,
                        paddingBottom: 8,
                        paddingLeft: 12,
                        margin: 5,
                        borderBottom: "1px solid rgb(2, 224, 164)",
                      }}
                    >
                      <DebugModeInfo
                        label="LineVerifiedBottleTotalPieces"
                        top={-10}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          minHeight: 72,
                          position: "relative",
                        }}
                      >
                        <Box
                          sx={{
                            width: 42,
                            p: 0,
                            height: 42,
                            mr: 1.5,
                            display: "flex",
                            borderRadius: 2,
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "rgba(2, 224, 164, 0.21)",
                            color: "rgb(2, 224, 164)",
                            borderBottom: "2px solid rgb(2, 224, 164)",
                          }}
                        >
                          <svg
                            style={{
                              margin: 5,
                              filter:
                                "drop-shadow(1px 3px 5px rgba(2, 224, 164, 0.81))",
                            }}
                            width="64px"
                            height="64px"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            stroke="#2, 224, 164"
                          >
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              <path
                                d="M4 15.8294V15.75V8C4 7.69114 4.16659 7.40629 4.43579 7.25487L4.45131 7.24614L11.6182 3.21475L11.6727 3.18411C11.8759 3.06979 12.1241 3.06979 12.3273 3.18411L19.6105 7.28092C19.8511 7.41625 20 7.67083 20 7.94687V8V15.75V15.8294C20 16.1119 19.8506 16.3733 19.6073 16.5167L12.379 20.7766C12.1451 20.9144 11.8549 20.9144 11.621 20.7766L4.39267 16.5167C4.14935 16.3733 4 16.1119 4 15.8294Z"
                                stroke="#02e0a4"
                                strokeWidth="2"
                              ></path>
                              <path
                                d="M12 21V12"
                                stroke="#02e0a4"
                                strokeWidth="2"
                              ></path>
                              <path
                                d="M12 12L4 7.5"
                                stroke="#02e0a4"
                                strokeWidth="2"
                              ></path>
                              <path
                                d="M20 7.5L12 12"
                                stroke="#02e0a4"
                                strokeWidth="2"
                              ></path>
                            </g>
                          </svg>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "flex-end",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: "1.1rem",
                              lineHeight: "1.1rem",
                              color: "#CACFD2",
                            }}
                          >
                            Kutulanan Ürün
                          </Typography>
                          <Typography
                            sx={{
                              mt: 1,
                              fontWeight: 700,
                              fontSize: "1.2rem",
                              lineHeight: "1.2rem",
                              color: "#FBFCFC",
                            }}
                          >
                            {message.Line.VerifiedBottleTotalPieces}
                          </Typography>
                          <Typography
                            sx={{
                              position: "absolute",
                              bottom: 0,
                              fontWeight: 700,
                              fontSize: "0.7rem",
                              lineHeight: "0.7rem",
                              color: "#CACFD2",
                            }}
                          >
                            adet
                          </Typography>
                        </Box>
                      </Box>
                    </div>
                    <div
                      className="card"
                      style={{
                        width: 210,
                        paddingTop: 8,
                        paddingRight: 12,
                        paddingBottom: 8,
                        paddingLeft: 12,
                        margin: 5,
                        borderBottom: "1px solid rgb(243, 156, 18)",
                      }}
                    >
                      <DebugModeInfo
                        label="LineVerifiedBoxTotalPieces"
                        top={-10}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          minHeight: 72,
                          position: "relative",
                        }}
                      >
                        <Box
                          sx={{
                            width: 42,
                            p: 0,
                            height: 42,
                            mr: 1.5,
                            display: "flex",
                            borderRadius: 2,
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "rgba(243, 156, 18, 0.21)",
                            color: "rgb(243, 156, 18)",
                            borderBottom: "2px solid rgb(243, 156, 18)",
                          }}
                        >
                          <AutoModeRounded
                            sx={{
                              filter:
                                "drop-shadow(1px 3px 5px rgba(243, 156, 18, 0.81))",
                            }}
                          />
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "flex-end",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: "1.1rem",
                              lineHeight: "1.1rem",
                              color: "#CACFD2",
                            }}
                          >
                            Kutulama Tekrar
                          </Typography>
                          <Typography
                            sx={{
                              mt: 1,
                              fontWeight: 700,
                              fontSize: "1.2rem",
                              lineHeight: "1.2rem",
                              color: "#FBFCFC",
                            }}
                          >
                            {message.Line.RecycleBottles}
                          </Typography>
                          <Typography
                            sx={{
                              position: "absolute",
                              bottom: 0,
                              fontWeight: 700,
                              fontSize: "0.7rem",
                              lineHeight: "0.7rem",
                              color: "#CACFD2",
                            }}
                          >
                            adet
                          </Typography>
                        </Box>
                      </Box>
                    </div>
                    <div
                      className="card"
                      style={{
                        cursor: "pointer",
                        width: 210,
                        paddingTop: 8,
                        paddingRight: 12,
                        paddingBottom: 8,
                        paddingLeft: 12,
                        margin: 5,
                        borderBottom: "1px solid rgb(255, 139, 0)",
                      }}
                      onClick={() => setProductivityInfoDialogState(true)}
                    >
                      <DebugModeInfo label="LineWorkingTime" top={-10} />
                      <InfoTwoTone
                        sx={{
                          position: "absolute",
                          top: 5,
                          right: 5,
                          fontSize: "1rem",
                          color: "#d0d3d4",
                        }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          minHeight: 72,
                        }}
                      >
                        <Box
                          sx={{
                            width: 42,
                            p: 0,
                            height: 42,
                            display: "flex",
                            borderRadius: 2,
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "rgba(255, 139, 0, 0.21)",
                            color: "rgb(255, 139, 0)",
                            borderBottom: "2px solid rgb(255, 139, 0)",
                          }}
                        >
                          <SwitchAccessShortcutAddRounded
                            sx={{
                              fontSize: 32,
                              margin: 5,
                              filter:
                                "drop-shadow(1px 3px 5px rgba(255, 139, 0, 0.81))",
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "flex-end",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: "1.1rem",
                              lineHeight: "1.1rem",
                              color: "#CACFD2",
                            }}
                          >
                            Kullanılabilirlik
                          </Typography>
                          <Typography
                            sx={{
                              mt: 1,
                              fontWeight: 700,
                              fontSize: "1.2rem",
                              lineHeight: "1.2rem",
                              color: "#FBFCFC",
                            }}
                          >
                            {message.Line.Availability
                              ? message.Line.Availability.toFixed(1)
                              : 0}
                            %
                          </Typography>
                        </Box>
                      </Box>
                    </div>
                    <div
                      className="card"
                      style={{
                        cursor: "pointer",
                        width: 210,
                        paddingTop: 8,
                        paddingRight: 12,
                        paddingBottom: 8,
                        paddingLeft: 12,
                        margin: 5,
                        borderBottom: "1px solid rgb(253, 202, 64)",
                      }}
                      onClick={() => setProductivityInfoDialogState(true)}
                    >
                      <DebugModeInfo label="LineWorkingTime" top={-10} />
                      <InfoTwoTone
                        sx={{
                          position: "absolute",
                          top: 5,
                          right: 5,
                          fontSize: "1rem",
                          color: "#d0d3d4",
                        }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          minHeight: 72,
                        }}
                      >
                        <Box
                          sx={{
                            width: 42,
                            p: 0,
                            height: 42,
                            display: "flex",
                            borderRadius: 2,
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "rgba(253, 202, 64, 0.21)",
                            color: "rgb(253, 202, 64)",
                            borderBottom: "2px solid rgb(253, 202, 64)",
                          }}
                        >
                          <StormRounded
                            sx={{
                              fontSize: 32,
                              margin: 5,
                              filter:
                                "drop-shadow(1px 3px 5px rgba(253, 202, 64, 0.81))",
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "flex-end",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: "1.1rem",
                              lineHeight: "1.1rem",
                              color: "#CACFD2",
                            }}
                          >
                            Performans
                          </Typography>
                          <Typography
                            sx={{
                              mt: 1,
                              fontWeight: 700,
                              fontSize: "1.2rem",
                              lineHeight: "1.2rem",
                              color: "#FBFCFC",
                            }}
                          >
                            {message.Line.Performance
                              ? message.Line.Performance.toFixed(1)
                              : 0}{" "}
                            %
                          </Typography>
                        </Box>
                      </Box>
                    </div>
                    <Productivity
                      productivityValue={message.Line.OEE}
                      onDialogOpen={() => setProductivityInfoDialogState(true)}
                    />
                  </Box>
                </Box>
              </Box>
            </div>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            <div
              className="card"
              style={{
                position: "relative",
                paddingTop: 1,
                paddingRight: 4,
                paddingBottom: 4,
                paddingLeft: 4,
                marginTop: 20,
                borderTop: "1px solid rgba(255,255,255,0.75)",
                borderBottom: "1px solid rgba(255,255,255,0.75)",
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  py: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  gap: 0.5,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "1.2rem !important",
                    color: "#FBFCFC !important",
                  }}
                >
                  Üretim Akış Durumu
                </Typography>
                <Box
                  sx={{
                    position: { xs: "relative", sm: "absolute" },
                    top: 3,
                    right: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="card"
                    style={{
                      paddingTop: 8,
                      paddingRight: 14,
                      paddingBottom: 8,
                      paddingLeft: 14,
                      margin: 5,
                      borderBottom: `2px solid rgb(255, 255, 255)`,
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: "0.9rem !important",
                        color: "rgb(236, 240, 241) !important",
                      }}
                    >
                      {100} adet / dk
                    </Typography>
                  </div>
                </Box>
              </Box>
              <Box
                width="100%"
                display="flex"
                justifyContent="center"
                flexWrap="wrap"
                alignItems="center"
              >
                <div
                  className="card"
                  style={{
                    padding: 10,
                    margin: 5,
                    minWidth: 160,
                    minHeight: 65,
                    borderBottom: "2px solid rgb(4, 158, 230)",
                  }}
                >
                  <DebugModeInfo label="LineInBottlePieces" top={-10} />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "1.1rem",
                        lineHeight: "1.1rem",
                        color: "#CACFD2",
                        pt: 0.2,
                        pb: 2,
                      }}
                    >
                      Giren Şişe
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: 42,
                        height: 42,
                        px: 0,
                        mr: 1.5,
                        borderRadius: 2,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(4, 158, 230, 0.21)",
                        color: "rgba(4, 158, 230)",
                        borderBottom: "2px solid rgb(4, 158, 230)",
                      }}
                    >
                      <svg
                        style={{
                          margin: 7,
                          filter:
                            "drop-shadow(1px 3px 5px rgba(4, 158, 230, 0.81))",
                        }}
                        fill="#1F3140"
                        height="64px"
                        width="64px"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="-26.68 -26.68 320.14 320.14"
                        enableBackground="new 0 0 266.777 266.777"
                        transform="rotate(0)"
                        stroke="#1F3140"
                        strokeWidth="0.00266777"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          stroke="#049ee6"
                          strokeWidth="53.355399999999996"
                        >
                          <path d="m179.638,90.081c-7.693-6.157-11.276-15.514-11.276-29.446v-17.635h11.527v-43h-93v43h11.527v17.635c0,14.874-3.073,23.681-10.276,29.446-19.44,15.559-27.369,31.983-27.369,56.692v100.088c0,10.981 8.934,19.916 19.915,19.916h105.405c10.981,0 19.916-8.935 19.916-19.916v-100.088c0-22.616-9.611-43.28-26.369-56.692z"></path>{" "}
                        </g>
                        <g id="SVGRepo_iconCarrier">
                          <path d="m179.638,90.081c-7.693-6.157-11.276-15.514-11.276-29.446v-17.635h11.527v-43h-93v43h11.527v17.635c0,14.874-3.073,23.681-10.276,29.446-19.44,15.559-27.369,31.983-27.369,56.692v100.088c0,10.981 8.934,19.916 19.915,19.916h105.405c10.981,0 19.916-8.935 19.916-19.916v-100.088c0-22.616-9.611-43.28-26.369-56.692z"></path>{" "}
                        </g>
                      </svg>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        flexDirection: "column",
                        alignItems: "flex-end",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: "1.2rem",
                          lineHeight: "1.2rem",
                          color: "#FBFCFC",
                        }}
                      >
                        {message.Line.InBottlePieces}
                      </Typography>
                      <Typography
                        sx={{
                          mt: 1,
                          fontWeight: 500,
                          fontSize: "0.75rem",
                          lineHeight: "0.75rem",
                          color: "#CACFD2",
                        }}
                      >
                        Adet
                      </Typography>
                    </Box>
                  </Box>
                </div>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="card"
                    style={{
                      padding: 10,
                      margin: 5,
                      minWidth: 160,
                      minHeight: 65,
                      borderBottom: "2px solid rgb(155, 89, 182)",
                    }}
                  >
                    <DebugModeInfo
                      label="LineDolumBottleOutCounter"
                      top={-10}
                    />
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: "1.1rem",
                            lineHeight: "1.1rem",
                            color: "#CACFD2",
                            pt: 0.2,
                            pb: 2,
                          }}
                        >
                          Dolan Şişe
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            width: 42,
                            height: 42,
                            p: 0,
                            mr: 1.5,
                            borderRadius: 2,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "rgba(155, 89, 182, 0.21)",
                            color: "rgba(155, 89, 182)",
                            borderBottom: "2px solid rgb(155, 89, 182)",
                          }}
                        >
                          <svg
                            style={{
                              margin: 6,
                              filter:
                                "drop-shadow(1px 3px 5px rgba(155, 89, 182, 0.81))",
                            }}
                            fill="#9b59b6"
                            height="64px"
                            width="64px"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="-26.68 -26.68 320.14 320.14"
                            enableBackground="new 0 0 266.777 266.777"
                            transform="rotate(0)"
                            stroke="#9b59b6"
                            strokeWidth="0.00266777"
                          >
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              stroke="#9b59b6"
                              strokeWidth="13.872404"
                            >
                              <path d="m179.638,90.081c-7.693-6.157-11.276-15.514-11.276-29.446v-17.635h11.527v-43h-93v43h11.527v17.635c0,14.874-3.073,23.681-10.276,29.446-19.44,15.559-27.369,31.983-27.369,56.692v100.088c0,10.981 8.934,19.916 19.915,19.916h105.405c10.981,0 19.916-8.935 19.916-19.916v-100.088c0-22.616-9.611-43.28-26.369-56.692z"></path>{" "}
                            </g>
                            <g id="SVGRepo_iconCarrier">
                              <path d="m179.638,90.081c-7.693-6.157-11.276-15.514-11.276-29.446v-17.635h11.527v-43h-93v43h11.527v17.635c0,14.874-3.073,23.681-10.276,29.446-19.44,15.559-27.369,31.983-27.369,56.692v100.088c0,10.981 8.934,19.916 19.915,19.916h105.405c10.981,0 19.916-8.935 19.916-19.916v-100.088c0-22.616-9.611-43.28-26.369-56.692z"></path>{" "}
                            </g>
                          </svg>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            flexDirection: "column",
                            alignItems: "flex-end",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 700,
                              fontSize: "1.2rem",
                              lineHeight: "1.2rem",
                              color: "#FBFCFC",
                            }}
                          >
                            {message.Line.DolumBottleOutCounter}
                          </Typography>
                          <Typography
                            sx={{
                              mt: 1,
                              fontWeight: 500,
                              fontSize: "0.75rem",
                              lineHeight: "0.75rem",
                              color: "#CACFD2",
                            }}
                          >
                            Adet
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </div>
                  <div
                    className="card"
                    style={{
                      padding: 10,
                      margin: 5,
                      minWidth: 160,
                      minHeight: 65,
                      borderBottom: "2px solid rgb(255, 76, 81)",
                    }}
                  >
                    <DebugModeInfo
                      label="LineUnfillableBottlePieces"
                      top={-10}
                    />
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: "1.1rem",
                            lineHeight: "1.1rem",
                            color: "#CACFD2",
                            pt: 0.2,
                            pb: 2,
                          }}
                        >
                          Dolum Fire
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            width: 42,
                            p: 0,
                            height: 42,
                            mr: 1.5,
                            borderRadius: 2,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "rgba(255, 76, 81, 0.21)",
                            color: "rgba(255, 76, 81)",
                            borderBottom: "2px solid rgb(255, 76, 81)",
                          }}
                        >
                          <CloseRounded
                            sx={{
                              filter:
                                "drop-shadow(1px 3px 5px rgba(255, 76, 81, 0.81))",
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            flexDirection: "column",
                            alignItems: "flex-end",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 700,
                              fontSize: "1.2rem",
                              lineHeight: "1.2rem",
                              color: "#FBFCFC",
                            }}
                          >
                            {message.Line.UnfillableBottlePieces}
                          </Typography>
                          <Typography
                            sx={{
                              mt: 1,
                              fontWeight: 500,
                              fontSize: "0.75rem",
                              lineHeight: "0.75rem",
                              color: "#CACFD2",
                            }}
                          >
                            Adet
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </div>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="card"
                    style={{
                      padding: 10,
                      margin: 5,
                      minWidth: 160,
                      minHeight: 65,
                      borderBottom: "2px solid rgb(2, 224, 164)",
                    }}
                  >
                    <DebugModeInfo
                      label="LineVerifiedBottleTotalPieces"
                      top={-10}
                    />
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: "1.1rem",
                            lineHeight: "1.1rem",
                            color: "#CACFD2",
                            pt: 0.2,
                            pb: 2,
                          }}
                        >
                          Etiketli Ürün
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            width: 42,
                            p: 0,
                            height: 42,
                            mr: 1.5,
                            borderRadius: 2,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "rgba(2, 224, 164, 0.21)",
                            color: "rgba(2, 224, 164)",
                            borderBottom: "2px solid rgba(2, 224, 164)",
                          }}
                        >
                          <QrCodeRounded
                            sx={{
                              filter:
                                "drop-shadow(1px 3px 5px rgba(2, 224, 164, 0.81))",
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            flexDirection: "column",
                            alignItems: "flex-end",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 700,
                              fontSize: "1.2rem",
                              lineHeight: "1.2rem",
                              color: "#FBFCFC",
                            }}
                          >
                            {message.Line.VerifiedBottleTotalPieces}
                          </Typography>
                          <Typography
                            sx={{
                              mt: 1,
                              fontWeight: 500,
                              fontSize: "0.75rem",
                              lineHeight: "0.75rem",
                              color: "#CACFD2",
                            }}
                          >
                            Adet
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </div>
                  <div
                    className="card"
                    style={{
                      padding: 10,
                      margin: 5,
                      minWidth: 160,
                      minHeight: 65,
                      borderBottom: "2px solid rgb(255, 76, 81)",
                    }}
                  >
                    <DebugModeInfo
                      label="LineRejectBottleTotalPieces"
                      top={-10}
                    />
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: "1.1rem",
                            lineHeight: "1.1rem",
                            color: "#CACFD2",
                            pt: 0.2,
                            pb: 2,
                          }}
                        >
                          Etiket Fire
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            width: 42,
                            p: 0,
                            height: 42,
                            mr: 1.5,
                            display: "flex",
                            borderRadius: 2,
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "rgba(255, 76, 81, 0.21)",
                            color: "rgb(255, 76, 81)",
                            borderBottom: "2px solid rgb(255, 76, 81)",
                          }}
                        >
                          <CloseRounded
                            sx={{
                              filter:
                                "drop-shadow(1px 3px 5px rgba(255, 76, 81, 0.81))",
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            flexDirection: "column",
                            alignItems: "flex-end",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 700,
                              fontSize: "1.2rem",
                              lineHeight: "1.2rem",
                              color: "#FBFCFC",
                            }}
                          >
                            {message.Line.RejectBottleTotalPieces}
                          </Typography>
                          <Typography
                            sx={{
                              mt: 1,
                              fontWeight: 500,
                              fontSize: "0.75rem",
                              lineHeight: "0.75rem",
                              color: "#CACFD2",
                            }}
                          >
                            Adet
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </div>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="card"
                    style={{
                      padding: 10,
                      margin: 5,
                      minWidth: 160,
                      minHeight: 65,
                      borderBottom: "2px solid rgb(2, 224, 164)",
                    }}
                  >
                    <DebugModeInfo
                      label="LineVerifiedBoxTotalPieces"
                      top={-10}
                    />

                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: "1.1rem",
                            lineHeight: "1.1rem",
                            color: "#CACFD2",
                            pt: 0.2,
                            pb: 2,
                          }}
                        >
                          Kutulanan Ürün
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            width: 42,
                            p: 0,
                            height: 42,
                            mr: 1.5,
                            display: "flex",
                            borderRadius: 2,
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "rgba(2, 224, 164, 0.21)",
                            color: "rgb(2, 224, 164)",
                            borderBottom: "2px solid rgb(2, 224, 164)",
                          }}
                        >
                          <svg
                            style={{
                              margin: 5,
                              filter:
                                "drop-shadow(1px 3px 5px rgba(2, 224, 164, 0.81))",
                            }}
                            width="64px"
                            height="64px"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            stroke="#2, 224, 164"
                          >
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              <path
                                d="M4 15.8294V15.75V8C4 7.69114 4.16659 7.40629 4.43579 7.25487L4.45131 7.24614L11.6182 3.21475L11.6727 3.18411C11.8759 3.06979 12.1241 3.06979 12.3273 3.18411L19.6105 7.28092C19.8511 7.41625 20 7.67083 20 7.94687V8V15.75V15.8294C20 16.1119 19.8506 16.3733 19.6073 16.5167L12.379 20.7766C12.1451 20.9144 11.8549 20.9144 11.621 20.7766L4.39267 16.5167C4.14935 16.3733 4 16.1119 4 15.8294Z"
                                stroke="#02e0a4"
                                strokeWidth="2"
                              ></path>
                              <path
                                d="M12 21V12"
                                stroke="#02e0a4"
                                strokeWidth="2"
                              ></path>
                              <path
                                d="M12 12L4 7.5"
                                stroke="#02e0a4"
                                strokeWidth="2"
                              ></path>
                              <path
                                d="M20 7.5L12 12"
                                stroke="#02e0a4"
                                strokeWidth="2"
                              ></path>
                            </g>
                          </svg>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            flexDirection: "column",
                            alignItems: "flex-end",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 700,
                              fontSize: "1.2rem",
                              lineHeight: "1.2rem",
                              color: "#FBFCFC",
                            }}
                          >
                            {message.Line.VerifiedBoxTotalPieces}
                          </Typography>
                          <Typography
                            sx={{
                              mt: 1,
                              fontWeight: 500,
                              fontSize: "0.75rem",
                              lineHeight: "0.75rem",
                              color: "#CACFD2",
                            }}
                          >
                            Adet
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </div>
                  <div
                    className="card"
                    style={{
                      padding: 10,
                      margin: 5,
                      minWidth: 160,
                      minHeight: 65,
                      borderBottom: "2px solid rgb(243, 156, 18)",
                    }}
                  >
                    <DebugModeInfo label="LineRecycleBottles" top={-10} />
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: "1.1rem",
                            lineHeight: "1.1rem",
                            color: "#CACFD2",
                            pt: 0.2,
                            pb: 2,
                          }}
                        >
                          Kutulama Tekrar
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            width: 42,
                            p: 0,
                            height: 42,
                            mr: 1.5,
                            display: "flex",
                            borderRadius: 2,
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "rgba(243, 156, 18, 0.21)",
                            color: "rgb(243, 156, 18)",
                            borderBottom: "2px solid rgb(243, 156, 18)",
                          }}
                        >
                          <AutoModeRounded
                            sx={{
                              filter:
                                "drop-shadow(1px 3px 5px rgba(243, 156, 18, 0.81))",
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            flexDirection: "column",
                            alignItems: "flex-end",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 700,
                              fontSize: "1.2rem",
                              lineHeight: "1.2rem",
                              color: "#FBFCFC",
                            }}
                          >
                            {message.Line.RecycleBottles}
                          </Typography>
                          <Typography
                            sx={{
                              mt: 1,
                              fontWeight: 500,
                              fontSize: "0.75rem",
                              lineHeight: "0.75rem",
                              color: "#CACFD2",
                            }}
                          >
                            Adet
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </div>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="card"
                    style={{
                      padding: 10,
                      margin: 3,
                      minWidth: 160,
                      minHeight: 65,
                      borderBottom: "2px solid rgb(2, 224, 164)",
                    }}
                  >
                    <DebugModeInfo
                      label="LineVerifiedBottleTotalPieces"
                      top={-10}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "1.1rem",
                          lineHeight: "1.1rem",
                          color: "#CACFD2",
                          pt: 0.2,
                          pb: 2,
                        }}
                      >
                        Çıkan Ürün
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: 42,
                          p: 0,
                          height: 42,
                          mr: 1.5,
                          display: "flex",
                          borderRadius: 2,
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "rgba(2, 224, 164, 0.21)",
                          color: "rgb(2, 224, 164)",
                          borderBottom: "2px solid rgb(2, 224, 164)",
                        }}
                      >
                        <DoneOutlineRounded
                          sx={{
                            filter:
                              "drop-shadow(1px 3px 5px rgba(2, 224, 164, 0.81))",
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          flexDirection: "column",
                          alignItems: "flex-end",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: "1.2rem",
                            lineHeight: "1.2rem",
                            color: "#FBFCFC",
                          }}
                        >
                          {message.Line.VerifiedBottleTotalPieces}
                        </Typography>
                        <Typography
                          sx={{
                            mt: 1,
                            fontWeight: 500,
                            fontSize: "0.75rem",
                            lineHeight: "0.75rem",
                            color: "#CACFD2",
                          }}
                        >
                          Adet
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                  <div
                    className="card"
                    style={{
                      padding: 10,
                      margin: 5,
                      minWidth: 160,
                      minHeight: 65,
                      borderBottom: "2px solid rgb(255, 76, 81)",
                    }}
                  >
                    <DebugModeInfo label="LineRejectTotalPieces" top={-10} />
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: "1.1rem",
                            lineHeight: "1.1rem",
                            color: "#CACFD2",
                            pt: 0.2,
                            pb: 2,
                          }}
                        >
                          Toplam Fire
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            width: 42,
                            p: 0,
                            height: 42,
                            mr: 1.5,
                            display: "flex",
                            borderRadius: 2,
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "rgba(255, 76, 81, 0.21)",
                            color: "rgb(255, 76, 81)",
                            borderBottom: "2px solid rgb(255, 76, 81)",
                          }}
                        >
                          <CloseRounded
                            sx={{
                              filter:
                                "drop-shadow(1px 3px 5px rgba(255, 76, 81, 0.81))",
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            flexDirection: "column",
                            alignItems: "flex-end",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 700,
                              fontSize: "1.2rem",
                              lineHeight: "1.2rem",
                              color: "#FBFCFC",
                            }}
                          >
                            {message.Line.RejectTotalPieces}
                          </Typography>
                          <Typography
                            sx={{
                              mt: 1,
                              fontWeight: 500,
                              fontSize: "0.75rem",
                              lineHeight: "0.75rem",
                              color: "#CACFD2",
                            }}
                          >
                            Adet
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </div>
                </Box>
                <div
                  className="card"
                  style={{
                    padding: 10,
                    margin: 3,
                    minWidth: 160,
                    minHeight: 65,
                    borderBottom: "2px solid rgb(2, 224, 164)",
                  }}
                >
                  <DebugModeInfo label="LinePackageOutPieces" top={-10} />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "1.1rem",
                        lineHeight: "1.1rem",
                        color: "#CACFD2",
                        pt: 0.2,
                        pb: 2,
                      }}
                    >
                      Çıkan Koli
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: 42,
                        p: 0,
                        height: 42,
                        mr: 1.5,
                        display: "flex",
                        borderRadius: 2,
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(2, 224, 164, 0.21)",
                        color: "rgb(2, 224, 164)",
                        borderBottom: "2px solid rgb(2, 224, 164)",
                      }}
                    >
                      <Inventory2Rounded
                        sx={{
                          filter:
                            "drop-shadow(1px 3px 5px rgba(2, 224, 164, 0.81))",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        flexDirection: "column",
                        alignItems: "flex-end",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: "1.2rem",
                          lineHeight: "1.2rem",
                          color: "#FBFCFC",
                        }}
                      >
                        {message.Line.PackageOutPieces}
                      </Typography>
                      <Typography
                        sx={{
                          mt: 1,
                          fontWeight: 500,
                          fontSize: "0.75rem",
                          lineHeight: "0.75rem",
                          color: "#CACFD2",
                        }}
                      >
                        Adet
                      </Typography>
                    </Box>
                  </Box>
                </div>
              </Box>
            </div>
          </Box>
          <SelectedMachineInfoDialog
            selectedMachine={selectedMachine}
            setSelectedMachine={setSelectedMachine}
          />
          <LineDownInfoDialog
            lineInfoData={message.Line}
            machines={message.Machines}
            dialogState={lineDownInfoDialogState}
            setDialogState={setLineDownInfoDialogState}
          />
          <LineBreakInfoDialog
            lineInfoData={message.Line}
            dialogState={lineBreakInfoDialogState}
            setDialogState={setLineBreakInfoDialogState}
          />
          <LineRunInfoDialog
            lineInfoData={message.Line}
            dialogState={lineRunInfoDialogState}
            setDialogState={setLineRunInfoDialogState}
          />
          <ProductivityInfoDialog
            productivityInfoData={productivityDatas}
            machines={message.Machines}
            dialogState={productivityInfoDialogState}
            setDialogState={setProductivityInfoDialogState}
          />
        </Box>
        <Container
          maxWidth="xl"
          sx={{
            mt: 3,
            minHeight: 300,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: { xs: "center", lg: "space-between" },
              gap: 1.5,
            }}
          >
            {message.Machines.map((machine, index) => (
              <MachineLight
                final={true}
                title={machine.name}
                machine={machine}
                tableInfo={
                  machine.name === "Dolum"
                    ? message.Line.DolumTurnTableFull
                    : false
                }
                selectedMachine={selectedMachine}
                setSelectedMachine={setSelectedMachine}
              />
            ))}
          </Box>
        </Container>
        <Box
          sx={{
            width: "100%",
            position: "absolute",
            bottom: 1,
          }}
        >
          <Footer />
        </Box>
      </Box>
    </>
  );
};

export default PageShiftDetailsPage;
