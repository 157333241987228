const Logo = () => {
  return (
    <svg
    width={255.12}
    height={49}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    id="katman_1"
    x="0px"
    y="0px"
    xmlSpace="preserve"
    viewBox="70.86 247.72 425.21 81.67"
  >
    <style type="text/css">
      {
        "\t\t\t.st0{clip-path:url(#SVGID_00000084490607258720707060000008013083603456610960_);fill:url(#SVGID_00000096048583434827906070000013451281193478145160_);}\t\t\t.st1{clip-path:url(#SVGID_00000002342338899952785810000009547329261362928262_);fill:url(#SVGID_00000092416993443678088610000000955108803432568749_);}\t\t\t.st2{clip-path:url(#SVGID_00000029744979453937707440000017795664712981054357_);fill:url(#SVGID_00000050630840077089348200000006916434087899496121_);}\t.st3{fill:#fff;}"
      }
    </style>
    <g>
      <g>
        <g>
          <defs>
            <path
              id="SVGID_1_"
              d="M180.52,295.32c0,4.56-1.77,8.84-5,12.06l-12.64,12.64c-3.22,3.22-7.51,5-12.06,5h-17.88      c-4.56,0-8.84-1.77-12.06-5l-12.64-12.64c-3.22-3.22-5-7.51-5-12.06l0-17.88c0-4.56,1.77-8.84,5-12.06l0.33-0.33l6.76,6.76      l-0.33,0.33c-1.42,1.42-2.2,3.3-2.2,5.31l0,17.88c0,2,0.78,3.89,2.2,5.31l12.64,12.64c1.42,1.42,3.3,2.2,5.31,2.2h17.88      c2,0,3.89-0.78,5.31-2.2l12.64-12.64c1.42-1.42,2.2-3.3,2.2-5.31l0-17.88c0-2-0.78-3.89-2.2-5.31l-12.64-12.64      c-1.42-1.42-3.3-2.2-5.31-2.2h-17.88c-2,0-3.89,0.78-5.31,2.2l-0.28,0.28l-6.76-6.76l0.28-0.28c3.22-3.22,7.51-5,12.06-5h17.88      c4.56,0,8.84,1.77,12.06,5l12.64,12.64c3.22,3.22,5,7.51,5,12.06L180.52,295.32z"
            />
          </defs>
          <clipPath id="SVGID_00000130621053152912141680000006203365939498666123_">
            <use
              xlinkHref="#SVGID_1_"
              style={{
                overflow: "visible",
              }}
            />
          </clipPath>
          <linearGradient
            id="SVGID_00000121995314730044665190000017570575119333972630_"
            gradientUnits="userSpaceOnUse"
            x1={77.8689}
            y1={336.3858}
            x2={173.5205}
            y2={240.7342}
          >
            <stop
              offset={0}
              style={{
                stopColor: "#00699D",
              }}
            />
            <stop
              offset={0.0191}
              style={{
                stopColor: "#006A9D",
              }}
            />
            <stop
              offset={0.2875}
              style={{
                stopColor: "#007897",
              }}
            />
            <stop
              offset={0.5463}
              style={{
                stopColor: "#008190",
              }}
            />
            <stop
              offset={0.7892}
              style={{
                stopColor: "#00868B",
              }}
            />
            <stop
              offset={1}
              style={{
                stopColor: "#008789",
              }}
            />
          </linearGradient>
          <rect
            x={70.87}
            y={247.74}
            style={{
              clipPath:
                "url(#SVGID_00000130621053152912141680000006203365939498666123_)",
              fill: "url(#SVGID_00000121995314730044665190000017570575119333972630_)",
            }}
            width={109.66}
            height={81.65}
          />
        </g>
        <g>
          <defs>
            <path
              id="SVGID_00000139283514994063625130000009084033544513983635_"
              d="M115.33,319.74l-6.76-6.76l-0.28,0.28      c-1.42,1.42-3.3,2.2-5.31,2.2H87.96c-1.61-2.68-4.54-4.48-7.9-4.48c-5.08,0-9.2,4.12-9.2,9.2s4.12,9.2,9.2,9.2      c3.31,0,6.2-1.74,7.83-4.36h15.09c4.56,0,8.84-1.77,12.06-5L115.33,319.74z M83.75,320.18c0,2.03-1.65,3.68-3.68,3.68      c-2.03,0-3.68-1.65-3.68-3.68s1.65-3.68,3.68-3.68C82.1,316.5,83.75,318.15,83.75,320.18z"
            />
          </defs>
          <clipPath id="SVGID_00000095308167521813662560000006653418987154616755_">
            <use
              xlinkHref="#SVGID_00000139283514994063625130000009084033544513983635_"
              style={{
                overflow: "visible",
              }}
            />
          </clipPath>
          <linearGradient
            id="SVGID_00000173162891621969019810000011848286076321361026_"
            gradientUnits="userSpaceOnUse"
            x1={77.8689}
            y1={336.3858}
            x2={173.5205}
            y2={240.7342}
          >
            <stop
              offset={0}
              style={{
                stopColor: "#00699D",
              }}
            />
            <stop
              offset={0.0191}
              style={{
                stopColor: "#006A9D",
              }}
            />
            <stop
              offset={0.2875}
              style={{
                stopColor: "#007897",
              }}
            />
            <stop
              offset={0.5463}
              style={{
                stopColor: "#008190",
              }}
            />
            <stop
              offset={0.7892}
              style={{
                stopColor: "#00868B",
              }}
            />
            <stop
              offset={1}
              style={{
                stopColor: "#008789",
              }}
            />
          </linearGradient>
          <rect
            x={70.87}
            y={247.74}
            style={{
              clipPath:
                "url(#SVGID_00000095308167521813662560000006653418987154616755_)",
              fill: "url(#SVGID_00000173162891621969019810000011848286076321361026_)",
            }}
            width={109.66}
            height={81.65}
          />
        </g>
        <g>
          <defs>
            <path
              id="SVGID_00000003784099862161003940000013097086177750399129_"
              d="M132.68,295.32c0,4.56-1.77,8.84-5,12.06l-0.33,0.33      l-6.76-6.76l0.33-0.33c1.42-1.42,2.2-3.3,2.2-5.31l0-17.88c0-2-0.78-3.89-2.2-5.31l-12.64-12.64c-1.42-1.42-3.3-2.2-5.31-2.2      h-11.3H75.64c-2.64,0-4.78-2.14-4.78-4.78c0-2.64,2.14-4.78,4.78-4.78h16.04h11.3c4.56,0,8.84,1.77,12.06,5l12.64,12.64      c3.22,3.22,5,7.51,5,12.06L132.68,295.32z"
            />
          </defs>
          <clipPath id="SVGID_00000166640361339733285050000012373495195999027598_">
            <use
              xlinkHref="#SVGID_00000003784099862161003940000013097086177750399129_"
              style={{
                overflow: "visible",
              }}
            />
          </clipPath>
          <linearGradient
            id="SVGID_00000105427374908637196020000007517647202468102332_"
            gradientUnits="userSpaceOnUse"
            x1={77.8689}
            y1={336.3858}
            x2={173.5205}
            y2={240.7342}
          >
            <stop
              offset={0}
              style={{
                stopColor: "#00699D",
              }}
            />
            <stop
              offset={0.0191}
              style={{
                stopColor: "#006A9D",
              }}
            />
            <stop
              offset={0.2875}
              style={{
                stopColor: "#007897",
              }}
            />
            <stop
              offset={0.5463}
              style={{
                stopColor: "#008190",
              }}
            />
            <stop
              offset={0.7892}
              style={{
                stopColor: "#00868B",
              }}
            />
            <stop
              offset={1}
              style={{
                stopColor: "#008789",
              }}
            />
          </linearGradient>
          <rect
            x={70.87}
            y={247.74}
            style={{
              clipPath:
                "url(#SVGID_00000166640361339733285050000012373495195999027598_)",
              fill: "url(#SVGID_00000105427374908637196020000007517647202468102332_)",
            }}
            width={109.66}
            height={81.65}
          />
        </g>
      </g>
      <g>
        <path
          className="st3"
          d="M277.28,264.65v4.78h-2.39h-21.52v13.15h15.06h2.39v4.78h-2.39h-15.06v13.15h21.52h2.39v4.78h-2.39h-23.91    h-2.39v-2.39v-15.54v-4.78v-15.54v-2.39h2.39h23.91H277.28z"
        />
        <path
          className="st3"
          d="M317.09,305.29h-5.2l1.43-3.35l15.54-35.87l0.66-1.43h3.11l0.66,1.43l15.54,35.87l1.43,3.35h-5.2l-0.66-1.43    l-13.33-30.79l-13.33,30.79L317.09,305.29z"
        />
        <polygon
          className="st3"
          points="308.05,300.51 305.66,300.51 290.73,300.51 290.73,267.04 290.73,264.65 285.94,264.65     285.94,267.04 285.94,302.9 285.94,305.29 288.34,305.29 305.66,305.29 305.99,305.29 307.42,301.95   "
        />
        <path
          className="st3"
          d="M368.86,302.9v2.39h-4.78v-2.39v-17.16l-11.12-17.4l-2.33-3.71h5.68l0.72,1.08l9.44,14.76l9.21-14.71    l0.72-1.14h5.62l-2.27,3.65l-10.88,17.34V302.9z"
        />
        <path
          className="st3"
          d="M430.31,264.71h2.51l-0.06,1.2l-0.06,26.24c0,2.55,0.9,4.82,2.69,6.81c2.39,2.63,5.48,3.95,9.27,3.95    c2.87,0,5.44-0.84,7.71-2.51c2.83-2.15,4.24-4.9,4.24-8.25v-26.3v-1.14h2.51l-0.06,1.2L459,292.14c0,3.27-1.18,6.16-3.53,8.67    c-2.87,2.99-6.48,4.48-10.82,4.48c-3.71,0-6.95-1.16-9.74-3.47c-3.07-2.63-4.6-5.86-4.6-9.68v-26.3V264.71z"
        />
        <path
          className="st3"
          d="M494.87,264.65h1.2v2.39h-1.2h-13.09v37.06v1.2h-2.39v-1.2v-37.06h-13.21h-1.2v-2.39h1.2h13.21h2.39H494.87z"
        />
        <path
          className="st3"
          d="M239.11,280.25c0-2.4-0.93-4.65-2.63-6.34l-6.68-6.68c-1.69-1.69-3.94-2.63-6.34-2.63h-8.57h-0.87h-1.29    h-0.84h-3.95v40.74h3.95h0.84h1.29h0.87h8.57c2.39,0,4.65-0.93,6.34-2.63l6.68-6.68c1.69-1.69,2.63-3.94,2.63-6.34L239.11,280.25z     M232.99,292.53l-6.68,6.68c-0.76,0.76-1.77,1.18-2.84,1.18h-9.45c0,0,0,0,0,0h-1.29v-0.23v-2.77v-24.83v-2.77v-0.23h1.29h0.87    h8.57c1.07,0,2.08,0.42,2.84,1.18l6.68,6.68c0.76,0.76,1.18,1.77,1.18,2.84l0,9.45C234.16,290.77,233.75,291.77,232.99,292.53z"
        />
        <path
          className="st3"
          d="M404.01,267.11c9.85,0,17.86,8.01,17.86,17.86s-8.01,17.86-17.86,17.86c-9.85,0-17.86-8.01-17.86-17.86    S394.16,267.11,404.01,267.11 M404.01,264.41c-11.36,0-20.56,9.21-20.56,20.56s9.21,20.56,20.56,20.56    c11.36,0,20.56-9.21,20.56-20.56S415.36,264.41,404.01,264.41L404.01,264.41z"
        />
      </g>
    </g>
  </svg>
  );
};

export default Logo;
