import { Box, Typography } from "@mui/material";

const Footer = () => {
    return (
        <Box
        sx={{
            width: '100%',
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            px: 1,
            py: 0.5,
            borderRadius: 1,
            backdropFilter: 'blur(36px)',
          }}
        >
          <Typography
            sx={{
              fontFamily: "inherit",
              fontWeight: 500,
              fontSize: "0.75rem",
              lineHeight: 2,
              color: "#f9f9f9",
            }}
            align="center"
          >
            {"Copyright © "}
            {new Date().getFullYear()} Delayout | All Rights Reserved
          </Typography>
        </Box>
      </Box>
    );
}

export default Footer;