import React from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { convertMsToTime } from "../App";
import { useEffect, useState } from "react";
import ProductivityForMachine from "./ProductivityForMachine";
import {
  InfoTwoTone,
  MoodRounded,
  SentimentDissatisfiedRounded,
  SentimentNeutralRounded,
  SentimentSatisfiedAltRounded,
} from "@mui/icons-material";
import { Machines } from "../message";

type MachineLightProps = {
  final?: boolean;
  title: string;
  machine: Machines[0];
  tableInfo?: boolean;
  selectedMachine: Machines[0] | null;
  setSelectedMachine: (value: React.SetStateAction<Machines[0] | null>) => void;
};

const MachineLight = (props: MachineLightProps) => {
  const {
    final = false,
    title,
    selectedMachine,
    setSelectedMachine,
    machine = {
      id: 0,
      name: "",
      Green: false,
      Red: false,
      Yellow: false,
      IdleTime: 0,
      DownTime: 0,
      RunTime: 0,
      WarningTime: 0,
      Efficiency: 0,
      Availability: 0,
      Performance: 0,
      Quality: 0,
      Sensors: [],
      Errors: [],
    },
    tableInfo,
  } = props;

  const [machineStateColor, setMachineStateColor] = useState<string>("");
  const [machineStateLabel, setMachineStateLabel] = useState<string>("");

  useEffect(() => {
    if (machine.Red) {
      setMachineStateColor("255, 56, 34");
      setMachineStateLabel("Durduruldu");
    } else if (machine.Yellow) {
      setMachineStateColor("243, 156, 18");
      setMachineStateLabel("Bekliyor");
    } else if (machine.Green) {
      setMachineStateColor("6, 255, 89");
      setMachineStateLabel("Çalışıyor");
    } else {
      setMachineStateColor("255, 255, 255");
      setMachineStateLabel("Kapalı");
    }
  }, [machine.Green, machine.Red, machine.Yellow, props.machine]);

  useEffect(() => {
    if (machine.machine_type === selectedMachine?.machine_type) {
      setSelectedMachine(machine);
    }
  }, [machine]);

  const [productivityColor, setProductivityValue] =
    useState<string>("2, 224, 164");
  const [productivityEmoji, setProductivityEmoji] = useState<any>();

  const returnColor = () => {
    if (machine.Efficiency >= 85) {
      return "2, 224, 164";
    }

    if (machine.Efficiency >= 60) {
      return "131, 217, 20";
    }

    if (machine.Efficiency >= 40) {
      return "243, 156, 18";
    }

    return "255, 76, 81";
  };

  useEffect(() => {
    setProductivityValue(returnColor());
    setProductivityEmoji(returnEmoji());
  }, [machine.Efficiency]);

  const returnEmoji = () => {
    if (machine.Efficiency >= 85) {
      return (
        <MoodRounded
          sx={{
            fontSize: 48,
          }}
        />
      );
    }

    if (machine.Efficiency >= 60) {
      return <SentimentSatisfiedAltRounded sx={{ fontSize: 48 }} />;
    }

    if (machine.Efficiency >= 40) {
      return (
        <SentimentNeutralRounded
          sx={{
            fontSize: 48,
          }}
        />
      );
    }

    return (
      <SentimentDissatisfiedRounded
        sx={{
          fontSize: 48,
        }}
      />
    );
  };

  return (
    <Tooltip title="Makine Bilgilerini Detaylı Görüntüle">
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            width: 152,
            px: 1.5,
            top: 0,
            paddingTop: 0.5,
            paddingBottom: 9,
            borderRadius: 3,
            backgroundColor: `rgba(${productivityColor}, 0.21)`,
            color: `rgb(${productivityColor})`,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "0.85rem",
            }}
          >
            OEE
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "0.85rem",
            }}
          >
            {machine.Efficiency.toFixed(1)} %
          </Typography>
        </Box>
        <div
          className="card no-bottom-radius"
          style={{
            paddingTop: 8,
            paddingRight: 14,
            paddingBottom: 8,
            paddingLeft: 14,
            margin: 5,
            marginTop: 27,
            marginBottom: 0,
            borderBottomRightRadius: final ? 18 : 0,
            borderBottomLeftRadius: final ? 18 : 0,
            cursor: "pointer",
            width: 152,
          }}
          onClick={() => setSelectedMachine(machine)}
        >
          <InfoTwoTone
            sx={{
              position: "absolute",
              top: 5,
              right: 5,
              fontSize: "1rem",
              color: "#d0d3d4",
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "1.1rem",
                  lineHeight: 1,
                  my: 1.5,
                  color: "#e6e6e6 !important",
                }}
              >
                {title}
              </Typography>
            </Box>
            <ProductivityForMachine
              availibilityValue={machine.Availability}
              performanceValue={machine.Performance}
            />
            <Box>
              <Box
                sx={{
                  maxWidth: 130,
                  margin: "0 auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {/* <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                borderRadius: 2,
                px: 0.8,
                py: 0.4,
                my: 0.75,
                minWidth: 75,
                backgroundColor: "rgba(149, 165, 166, 0.13)",
                ":hover": {
                  backgroundColor: "rgba(149, 165, 166, 0.21)",
                },
                border: "1px solid rgba(149, 165, 166)",
              }}
            >
              <Typography
                sx={{
                  fontSize: "0.9rem !important",
                  color: "rgba(149, 165, 166) !important",
                }}
              >
                {convertMsToTime(lights?.IdleTime || 0)}
              </Typography>
            </Box> */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      borderRadius: 2,
                      px: 0.8,
                      py: 0.4,
                      my: 0.75,
                      minWidth: 81,
                      backgroundColor: "rgba(255, 56, 34, 0.13)",
                      ":hover": {
                        backgroundColor: "rgba(255, 56, 34, 0.21)",
                      },
                      borderBottom: "2px solid rgba(255, 56, 34)",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "0.9rem !important",
                        color: "rgba(255, 56, 34) !important",
                      }}
                    >
                      {convertMsToTime(machine?.DownTime || 0)}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      borderRadius: 2,
                      px: 0.8,
                      py: 0.4,
                      my: 0.75,
                      minWidth: 81,
                      backgroundColor: "rgba(243, 156, 18, 0.13)",
                      ":hover": {
                        backgroundColor: "rgba(243, 156, 18, 0.21)",
                      },
                      borderBottom: "2px solid rgb(243, 156, 18)",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "0.9rem !important",
                        color: "rgb(243, 156, 18) !important",
                      }}
                    >
                      {convertMsToTime(machine?.WarningTime || 0)}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      borderRadius: 2,
                      px: 0.8,
                      py: 0.4,
                      my: 0.75,
                      minWidth: 81,
                      backgroundColor: "rgba(6, 255, 89, 0.13)",
                      ":hover": {
                        backgroundColor: "rgba(6, 255, 89, 0.21)",
                      },
                      borderBottom: "2px solid rgb(6, 255, 89)",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "0.9rem !important",
                        color: "rgb(6, 255, 89) !important",
                      }}
                    >
                      {convertMsToTime(machine?.RunTime || 0)}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    borderRadius: 1.5,
                    padding: 0.5,
                    ml: 0.5,
                    mt: 0.1,
                  }}
                >
                  <Box
                    sx={{
                      width: 30,
                      height: 30,
                      borderRadius: 1,
                      mt: 0.25,
                      mb: 0.75,
                      backgroundColor: machine?.Red ? "#ff3822" : "#2a2b2c",
                      boxShadow: machine?.Red
                        ? "0 0 75px 13px #ff3822"
                        : "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                    }}
                  ></Box>
                  <Box
                    sx={{
                      width: 30,
                      height: 30,
                      borderRadius: 1,
                      my: 0.75,
                      backgroundColor: machine?.Yellow ? "#F39C12" : "#2a2b2c",
                      boxShadow: machine?.Yellow
                        ? "0 0 75px 13px #F39C12"
                        : "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                    }}
                  ></Box>
                  <Box
                    sx={{
                      width: 30,
                      height: 30,
                      borderRadius: 1,
                      my: 0.75,
                      backgroundColor: machine?.Green ? "#06ff59" : "#2a2b2c",
                      boxShadow: machine?.Green
                        ? "0 0 75px 13px #06ff59"
                        : "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                    }}
                  ></Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </div>
        <Box
          sx={{
            display: final ? "none" : "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            background: `rgba(${machineStateColor}, 0.13)`,
            gap: 1,
            width: "100%",
            maxWidth: 154,
            borderBottomRightRadius: 15,
            borderBottomLeftRadius: 15,
            p: 1,
          }}
        >
          <Typography
            sx={{
              color: `rgb(${machineStateColor})`,
            }}
            variant="subtitle2"
          >
            {machineStateLabel}
          </Typography>
          {tableInfo && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                borderRadius: 2,
                px: 0.8,
                py: 0.4,
                minWidth: 75,
                backgroundColor: "rgba(243, 156, 18, 0.13)",
                ":hover": {
                  backgroundColor: "rgba(243, 156, 18, 0.21)",
                },
                border: "1px solid rgb(243, 156, 18)",
              }}
            >
              <Typography
                sx={{
                  fontSize: "0.9rem !important",
                  color: "rgb(243, 156, 18) !important",
                }}
              >
                Tabla Dolu
              </Typography>
            </Box>
          )}
          {machine.Errors.map((item, index) => (
            <Box
              key={index}
              sx={{
                width: "100%",
                display: item.toLowerCase() === "durduruldu" ? "none" : "flex",
                justifyContent: "center",
                borderRadius: 2,
                px: 0.8,
                py: 0.4,
                minWidth: 75,
                backgroundColor: "rgba(255, 56, 34, 0.13)",
                ":hover": {
                  backgroundColor: "rgba(255, 56, 34, 0.21)",
                },
                border: "1px solid rgb(255, 56, 34)",
              }}
            >
              <Typography
                sx={{
                  fontSize: "0.9rem !important",
                  color: "rgb(255, 56, 34) !important",
                }}
              >
                {item}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Tooltip>
  );
};

export default MachineLight;
